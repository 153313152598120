// source: service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.patrickwhite256.drafto.Card', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.Colour', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.DraftMode', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.GetCurrentUserReq', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.GetCurrentUserResp', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.GetDraftStatusReq', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.GetDraftStatusResp', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.GetSeatReq', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.GetSeatResp', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.MakeSelectionReq', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.MakeSelectionResp', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.NewDraftReq', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.NewDraftResp', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.Pack', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.Rarity', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.SeatData', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.TakeSeatReq', null, global);
goog.exportSymbol('proto.patrickwhite256.drafto.TakeSeatResp', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.Pack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.patrickwhite256.drafto.Pack.repeatedFields_, null);
};
goog.inherits(proto.patrickwhite256.drafto.Pack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.Pack.displayName = 'proto.patrickwhite256.drafto.Pack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.Card = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.patrickwhite256.drafto.Card.repeatedFields_, null);
};
goog.inherits(proto.patrickwhite256.drafto.Card, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.Card.displayName = 'proto.patrickwhite256.drafto.Card';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.NewDraftReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patrickwhite256.drafto.NewDraftReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.NewDraftReq.displayName = 'proto.patrickwhite256.drafto.NewDraftReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.NewDraftResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.patrickwhite256.drafto.NewDraftResp.repeatedFields_, null);
};
goog.inherits(proto.patrickwhite256.drafto.NewDraftResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.NewDraftResp.displayName = 'proto.patrickwhite256.drafto.NewDraftResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.GetSeatReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patrickwhite256.drafto.GetSeatReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.GetSeatReq.displayName = 'proto.patrickwhite256.drafto.GetSeatReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.GetSeatResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.patrickwhite256.drafto.GetSeatResp.repeatedFields_, null);
};
goog.inherits(proto.patrickwhite256.drafto.GetSeatResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.GetSeatResp.displayName = 'proto.patrickwhite256.drafto.GetSeatResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.MakeSelectionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patrickwhite256.drafto.MakeSelectionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.MakeSelectionReq.displayName = 'proto.patrickwhite256.drafto.MakeSelectionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.MakeSelectionResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patrickwhite256.drafto.MakeSelectionResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.MakeSelectionResp.displayName = 'proto.patrickwhite256.drafto.MakeSelectionResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.GetDraftStatusReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patrickwhite256.drafto.GetDraftStatusReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.GetDraftStatusReq.displayName = 'proto.patrickwhite256.drafto.GetDraftStatusReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.GetDraftStatusResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.patrickwhite256.drafto.GetDraftStatusResp.repeatedFields_, null);
};
goog.inherits(proto.patrickwhite256.drafto.GetDraftStatusResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.GetDraftStatusResp.displayName = 'proto.patrickwhite256.drafto.GetDraftStatusResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.SeatData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.patrickwhite256.drafto.SeatData.repeatedFields_, null);
};
goog.inherits(proto.patrickwhite256.drafto.SeatData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.SeatData.displayName = 'proto.patrickwhite256.drafto.SeatData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.GetCurrentUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patrickwhite256.drafto.GetCurrentUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.GetCurrentUserReq.displayName = 'proto.patrickwhite256.drafto.GetCurrentUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.GetCurrentUserResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.patrickwhite256.drafto.GetCurrentUserResp.repeatedFields_, null);
};
goog.inherits(proto.patrickwhite256.drafto.GetCurrentUserResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.GetCurrentUserResp.displayName = 'proto.patrickwhite256.drafto.GetCurrentUserResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.TakeSeatReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patrickwhite256.drafto.TakeSeatReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.TakeSeatReq.displayName = 'proto.patrickwhite256.drafto.TakeSeatReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patrickwhite256.drafto.TakeSeatResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patrickwhite256.drafto.TakeSeatResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patrickwhite256.drafto.TakeSeatResp.displayName = 'proto.patrickwhite256.drafto.TakeSeatResp';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.patrickwhite256.drafto.Pack.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.Pack.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.Pack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.Pack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.Pack.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.patrickwhite256.drafto.Card.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.Pack}
 */
proto.patrickwhite256.drafto.Pack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.Pack;
  return proto.patrickwhite256.drafto.Pack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.Pack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.Pack}
 */
proto.patrickwhite256.drafto.Pack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.patrickwhite256.drafto.Card;
      reader.readMessage(value,proto.patrickwhite256.drafto.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.Pack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.Pack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.Pack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.Pack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.patrickwhite256.drafto.Card.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.patrickwhite256.drafto.Pack.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.Pack} returns this
 */
proto.patrickwhite256.drafto.Pack.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Card cards = 2;
 * @return {!Array<!proto.patrickwhite256.drafto.Card>}
 */
proto.patrickwhite256.drafto.Pack.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.patrickwhite256.drafto.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.patrickwhite256.drafto.Card, 2));
};


/**
 * @param {!Array<!proto.patrickwhite256.drafto.Card>} value
 * @return {!proto.patrickwhite256.drafto.Pack} returns this
*/
proto.patrickwhite256.drafto.Pack.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.patrickwhite256.drafto.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.patrickwhite256.drafto.Card}
 */
proto.patrickwhite256.drafto.Pack.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.patrickwhite256.drafto.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.patrickwhite256.drafto.Pack} returns this
 */
proto.patrickwhite256.drafto.Pack.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.patrickwhite256.drafto.Card.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.Card.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.Card.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.Card} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.Card.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    coloursList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    rarity: jspb.Message.getFieldWithDefault(msg, 5, 0),
    foil: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    dfc: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.Card}
 */
proto.patrickwhite256.drafto.Card.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.Card;
  return proto.patrickwhite256.drafto.Card.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.Card} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.Card}
 */
proto.patrickwhite256.drafto.Card.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 4:
      var value = /** @type {!Array<!proto.patrickwhite256.drafto.Colour>} */ (reader.readPackedEnum());
      msg.setColoursList(value);
      break;
    case 5:
      var value = /** @type {!proto.patrickwhite256.drafto.Rarity} */ (reader.readEnum());
      msg.setRarity(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFoil(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDfc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.Card.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.Card.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.Card} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.Card.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getColoursList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getRarity();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getFoil();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getDfc();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.patrickwhite256.drafto.Card.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.Card} returns this
 */
proto.patrickwhite256.drafto.Card.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.patrickwhite256.drafto.Card.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.Card} returns this
 */
proto.patrickwhite256.drafto.Card.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_url = 3;
 * @return {string}
 */
proto.patrickwhite256.drafto.Card.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.Card} returns this
 */
proto.patrickwhite256.drafto.Card.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Colour colours = 4;
 * @return {!Array<!proto.patrickwhite256.drafto.Colour>}
 */
proto.patrickwhite256.drafto.Card.prototype.getColoursList = function() {
  return /** @type {!Array<!proto.patrickwhite256.drafto.Colour>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.patrickwhite256.drafto.Colour>} value
 * @return {!proto.patrickwhite256.drafto.Card} returns this
 */
proto.patrickwhite256.drafto.Card.prototype.setColoursList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.patrickwhite256.drafto.Colour} value
 * @param {number=} opt_index
 * @return {!proto.patrickwhite256.drafto.Card} returns this
 */
proto.patrickwhite256.drafto.Card.prototype.addColours = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.patrickwhite256.drafto.Card} returns this
 */
proto.patrickwhite256.drafto.Card.prototype.clearColoursList = function() {
  return this.setColoursList([]);
};


/**
 * optional Rarity rarity = 5;
 * @return {!proto.patrickwhite256.drafto.Rarity}
 */
proto.patrickwhite256.drafto.Card.prototype.getRarity = function() {
  return /** @type {!proto.patrickwhite256.drafto.Rarity} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.patrickwhite256.drafto.Rarity} value
 * @return {!proto.patrickwhite256.drafto.Card} returns this
 */
proto.patrickwhite256.drafto.Card.prototype.setRarity = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool foil = 6;
 * @return {boolean}
 */
proto.patrickwhite256.drafto.Card.prototype.getFoil = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.patrickwhite256.drafto.Card} returns this
 */
proto.patrickwhite256.drafto.Card.prototype.setFoil = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool dfc = 7;
 * @return {boolean}
 */
proto.patrickwhite256.drafto.Card.prototype.getDfc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.patrickwhite256.drafto.Card} returns this
 */
proto.patrickwhite256.drafto.Card.prototype.setDfc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.NewDraftReq.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.NewDraftReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.NewDraftReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.NewDraftReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    setCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playerCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    draftMode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cubeId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.NewDraftReq}
 */
proto.patrickwhite256.drafto.NewDraftReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.NewDraftReq;
  return proto.patrickwhite256.drafto.NewDraftReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.NewDraftReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.NewDraftReq}
 */
proto.patrickwhite256.drafto.NewDraftReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSetCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayerCount(value);
      break;
    case 3:
      var value = /** @type {!proto.patrickwhite256.drafto.DraftMode} */ (reader.readEnum());
      msg.setDraftMode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCubeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.NewDraftReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.NewDraftReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.NewDraftReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.NewDraftReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayerCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDraftMode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCubeId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string set_code = 1;
 * @return {string}
 */
proto.patrickwhite256.drafto.NewDraftReq.prototype.getSetCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.NewDraftReq} returns this
 */
proto.patrickwhite256.drafto.NewDraftReq.prototype.setSetCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 player_count = 2;
 * @return {number}
 */
proto.patrickwhite256.drafto.NewDraftReq.prototype.getPlayerCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.patrickwhite256.drafto.NewDraftReq} returns this
 */
proto.patrickwhite256.drafto.NewDraftReq.prototype.setPlayerCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional DraftMode draft_mode = 3;
 * @return {!proto.patrickwhite256.drafto.DraftMode}
 */
proto.patrickwhite256.drafto.NewDraftReq.prototype.getDraftMode = function() {
  return /** @type {!proto.patrickwhite256.drafto.DraftMode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.patrickwhite256.drafto.DraftMode} value
 * @return {!proto.patrickwhite256.drafto.NewDraftReq} returns this
 */
proto.patrickwhite256.drafto.NewDraftReq.prototype.setDraftMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string cube_id = 4;
 * @return {string}
 */
proto.patrickwhite256.drafto.NewDraftReq.prototype.getCubeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.NewDraftReq} returns this
 */
proto.patrickwhite256.drafto.NewDraftReq.prototype.setCubeId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.patrickwhite256.drafto.NewDraftResp.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.NewDraftResp.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.NewDraftResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.NewDraftResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.NewDraftResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    tableId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seatIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.NewDraftResp}
 */
proto.patrickwhite256.drafto.NewDraftResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.NewDraftResp;
  return proto.patrickwhite256.drafto.NewDraftResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.NewDraftResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.NewDraftResp}
 */
proto.patrickwhite256.drafto.NewDraftResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSeatIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.NewDraftResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.NewDraftResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.NewDraftResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.NewDraftResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeatIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string table_id = 1;
 * @return {string}
 */
proto.patrickwhite256.drafto.NewDraftResp.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.NewDraftResp} returns this
 */
proto.patrickwhite256.drafto.NewDraftResp.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string seat_ids = 2;
 * @return {!Array<string>}
 */
proto.patrickwhite256.drafto.NewDraftResp.prototype.getSeatIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.patrickwhite256.drafto.NewDraftResp} returns this
 */
proto.patrickwhite256.drafto.NewDraftResp.prototype.setSeatIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.patrickwhite256.drafto.NewDraftResp} returns this
 */
proto.patrickwhite256.drafto.NewDraftResp.prototype.addSeatIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.patrickwhite256.drafto.NewDraftResp} returns this
 */
proto.patrickwhite256.drafto.NewDraftResp.prototype.clearSeatIdsList = function() {
  return this.setSeatIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.GetSeatReq.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.GetSeatReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.GetSeatReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.GetSeatReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.GetSeatReq}
 */
proto.patrickwhite256.drafto.GetSeatReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.GetSeatReq;
  return proto.patrickwhite256.drafto.GetSeatReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.GetSeatReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.GetSeatReq}
 */
proto.patrickwhite256.drafto.GetSeatReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.GetSeatReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.GetSeatReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.GetSeatReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.GetSeatReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string seat_id = 1;
 * @return {string}
 */
proto.patrickwhite256.drafto.GetSeatReq.prototype.getSeatId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.GetSeatReq} returns this
 */
proto.patrickwhite256.drafto.GetSeatReq.prototype.setSeatId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.patrickwhite256.drafto.GetSeatResp.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.GetSeatResp.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.GetSeatResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.GetSeatResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.GetSeatResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currentPack: (f = msg.getCurrentPack()) && proto.patrickwhite256.drafto.Pack.toObject(includeInstance, f),
    poolList: jspb.Message.toObjectList(msg.getPoolList(),
    proto.patrickwhite256.drafto.Card.toObject, includeInstance),
    packCount: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.GetSeatResp}
 */
proto.patrickwhite256.drafto.GetSeatResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.GetSeatResp;
  return proto.patrickwhite256.drafto.GetSeatResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.GetSeatResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.GetSeatResp}
 */
proto.patrickwhite256.drafto.GetSeatResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    case 3:
      var value = new proto.patrickwhite256.drafto.Pack;
      reader.readMessage(value,proto.patrickwhite256.drafto.Pack.deserializeBinaryFromReader);
      msg.setCurrentPack(value);
      break;
    case 4:
      var value = new proto.patrickwhite256.drafto.Card;
      reader.readMessage(value,proto.patrickwhite256.drafto.Card.deserializeBinaryFromReader);
      msg.addPool(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPackCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.GetSeatResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.GetSeatResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.GetSeatResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.GetSeatResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrentPack();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.patrickwhite256.drafto.Pack.serializeBinaryToWriter
    );
  }
  f = message.getPoolList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.patrickwhite256.drafto.Card.serializeBinaryToWriter
    );
  }
  f = message.getPackCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string seat_id = 1;
 * @return {string}
 */
proto.patrickwhite256.drafto.GetSeatResp.prototype.getSeatId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.GetSeatResp} returns this
 */
proto.patrickwhite256.drafto.GetSeatResp.prototype.setSeatId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.patrickwhite256.drafto.GetSeatResp.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.GetSeatResp} returns this
 */
proto.patrickwhite256.drafto.GetSeatResp.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Pack current_pack = 3;
 * @return {?proto.patrickwhite256.drafto.Pack}
 */
proto.patrickwhite256.drafto.GetSeatResp.prototype.getCurrentPack = function() {
  return /** @type{?proto.patrickwhite256.drafto.Pack} */ (
    jspb.Message.getWrapperField(this, proto.patrickwhite256.drafto.Pack, 3));
};


/**
 * @param {?proto.patrickwhite256.drafto.Pack|undefined} value
 * @return {!proto.patrickwhite256.drafto.GetSeatResp} returns this
*/
proto.patrickwhite256.drafto.GetSeatResp.prototype.setCurrentPack = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.patrickwhite256.drafto.GetSeatResp} returns this
 */
proto.patrickwhite256.drafto.GetSeatResp.prototype.clearCurrentPack = function() {
  return this.setCurrentPack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.patrickwhite256.drafto.GetSeatResp.prototype.hasCurrentPack = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Card pool = 4;
 * @return {!Array<!proto.patrickwhite256.drafto.Card>}
 */
proto.patrickwhite256.drafto.GetSeatResp.prototype.getPoolList = function() {
  return /** @type{!Array<!proto.patrickwhite256.drafto.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.patrickwhite256.drafto.Card, 4));
};


/**
 * @param {!Array<!proto.patrickwhite256.drafto.Card>} value
 * @return {!proto.patrickwhite256.drafto.GetSeatResp} returns this
*/
proto.patrickwhite256.drafto.GetSeatResp.prototype.setPoolList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.patrickwhite256.drafto.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.patrickwhite256.drafto.Card}
 */
proto.patrickwhite256.drafto.GetSeatResp.prototype.addPool = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.patrickwhite256.drafto.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.patrickwhite256.drafto.GetSeatResp} returns this
 */
proto.patrickwhite256.drafto.GetSeatResp.prototype.clearPoolList = function() {
  return this.setPoolList([]);
};


/**
 * optional int32 pack_count = 5;
 * @return {number}
 */
proto.patrickwhite256.drafto.GetSeatResp.prototype.getPackCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.patrickwhite256.drafto.GetSeatResp} returns this
 */
proto.patrickwhite256.drafto.GetSeatResp.prototype.setPackCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.MakeSelectionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.MakeSelectionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.MakeSelectionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.MakeSelectionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cardId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.MakeSelectionReq}
 */
proto.patrickwhite256.drafto.MakeSelectionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.MakeSelectionReq;
  return proto.patrickwhite256.drafto.MakeSelectionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.MakeSelectionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.MakeSelectionReq}
 */
proto.patrickwhite256.drafto.MakeSelectionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.MakeSelectionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.MakeSelectionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.MakeSelectionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.MakeSelectionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCardId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string seat_id = 1;
 * @return {string}
 */
proto.patrickwhite256.drafto.MakeSelectionReq.prototype.getSeatId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.MakeSelectionReq} returns this
 */
proto.patrickwhite256.drafto.MakeSelectionReq.prototype.setSeatId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string card_id = 2;
 * @return {string}
 */
proto.patrickwhite256.drafto.MakeSelectionReq.prototype.getCardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.MakeSelectionReq} returns this
 */
proto.patrickwhite256.drafto.MakeSelectionReq.prototype.setCardId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.MakeSelectionResp.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.MakeSelectionResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.MakeSelectionResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.MakeSelectionResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.MakeSelectionResp}
 */
proto.patrickwhite256.drafto.MakeSelectionResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.MakeSelectionResp;
  return proto.patrickwhite256.drafto.MakeSelectionResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.MakeSelectionResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.MakeSelectionResp}
 */
proto.patrickwhite256.drafto.MakeSelectionResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.MakeSelectionResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.MakeSelectionResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.MakeSelectionResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.MakeSelectionResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string seat_id = 1;
 * @return {string}
 */
proto.patrickwhite256.drafto.MakeSelectionResp.prototype.getSeatId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.MakeSelectionResp} returns this
 */
proto.patrickwhite256.drafto.MakeSelectionResp.prototype.setSeatId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.GetDraftStatusReq.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.GetDraftStatusReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.GetDraftStatusReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.GetDraftStatusReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    tableId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.GetDraftStatusReq}
 */
proto.patrickwhite256.drafto.GetDraftStatusReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.GetDraftStatusReq;
  return proto.patrickwhite256.drafto.GetDraftStatusReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.GetDraftStatusReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.GetDraftStatusReq}
 */
proto.patrickwhite256.drafto.GetDraftStatusReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.GetDraftStatusReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.GetDraftStatusReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.GetDraftStatusReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.GetDraftStatusReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string table_id = 1;
 * @return {string}
 */
proto.patrickwhite256.drafto.GetDraftStatusReq.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.GetDraftStatusReq} returns this
 */
proto.patrickwhite256.drafto.GetDraftStatusReq.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.patrickwhite256.drafto.GetDraftStatusResp.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.GetDraftStatusResp.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.GetDraftStatusResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.GetDraftStatusResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.GetDraftStatusResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    tableId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currentPack: jspb.Message.getFieldWithDefault(msg, 2, 0),
    seatsList: jspb.Message.toObjectList(msg.getSeatsList(),
    proto.patrickwhite256.drafto.SeatData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.GetDraftStatusResp}
 */
proto.patrickwhite256.drafto.GetDraftStatusResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.GetDraftStatusResp;
  return proto.patrickwhite256.drafto.GetDraftStatusResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.GetDraftStatusResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.GetDraftStatusResp}
 */
proto.patrickwhite256.drafto.GetDraftStatusResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPack(value);
      break;
    case 3:
      var value = new proto.patrickwhite256.drafto.SeatData;
      reader.readMessage(value,proto.patrickwhite256.drafto.SeatData.deserializeBinaryFromReader);
      msg.addSeats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.GetDraftStatusResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.GetDraftStatusResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.GetDraftStatusResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.GetDraftStatusResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrentPack();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSeatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.patrickwhite256.drafto.SeatData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string table_id = 1;
 * @return {string}
 */
proto.patrickwhite256.drafto.GetDraftStatusResp.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.GetDraftStatusResp} returns this
 */
proto.patrickwhite256.drafto.GetDraftStatusResp.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 current_pack = 2;
 * @return {number}
 */
proto.patrickwhite256.drafto.GetDraftStatusResp.prototype.getCurrentPack = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.patrickwhite256.drafto.GetDraftStatusResp} returns this
 */
proto.patrickwhite256.drafto.GetDraftStatusResp.prototype.setCurrentPack = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated SeatData seats = 3;
 * @return {!Array<!proto.patrickwhite256.drafto.SeatData>}
 */
proto.patrickwhite256.drafto.GetDraftStatusResp.prototype.getSeatsList = function() {
  return /** @type{!Array<!proto.patrickwhite256.drafto.SeatData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.patrickwhite256.drafto.SeatData, 3));
};


/**
 * @param {!Array<!proto.patrickwhite256.drafto.SeatData>} value
 * @return {!proto.patrickwhite256.drafto.GetDraftStatusResp} returns this
*/
proto.patrickwhite256.drafto.GetDraftStatusResp.prototype.setSeatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.patrickwhite256.drafto.SeatData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.patrickwhite256.drafto.SeatData}
 */
proto.patrickwhite256.drafto.GetDraftStatusResp.prototype.addSeats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.patrickwhite256.drafto.SeatData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.patrickwhite256.drafto.GetDraftStatusResp} returns this
 */
proto.patrickwhite256.drafto.GetDraftStatusResp.prototype.clearSeatsList = function() {
  return this.setSeatsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.patrickwhite256.drafto.SeatData.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.SeatData.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.SeatData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.SeatData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.SeatData.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    packCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    poolCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    poolRevealedCardsList: jspb.Message.toObjectList(msg.getPoolRevealedCardsList(),
    proto.patrickwhite256.drafto.Card.toObject, includeInstance),
    packRevealedCardsList: jspb.Message.toObjectList(msg.getPackRevealedCardsList(),
    proto.patrickwhite256.drafto.Card.toObject, includeInstance),
    currentPackCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    playerName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.SeatData}
 */
proto.patrickwhite256.drafto.SeatData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.SeatData;
  return proto.patrickwhite256.drafto.SeatData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.SeatData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.SeatData}
 */
proto.patrickwhite256.drafto.SeatData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPackCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPoolCount(value);
      break;
    case 4:
      var value = new proto.patrickwhite256.drafto.Card;
      reader.readMessage(value,proto.patrickwhite256.drafto.Card.deserializeBinaryFromReader);
      msg.addPoolRevealedCards(value);
      break;
    case 5:
      var value = new proto.patrickwhite256.drafto.Card;
      reader.readMessage(value,proto.patrickwhite256.drafto.Card.deserializeBinaryFromReader);
      msg.addPackRevealedCards(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPackCount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.SeatData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.SeatData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.SeatData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.SeatData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPackCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPoolCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPoolRevealedCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.patrickwhite256.drafto.Card.serializeBinaryToWriter
    );
  }
  f = message.getPackRevealedCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.patrickwhite256.drafto.Card.serializeBinaryToWriter
    );
  }
  f = message.getCurrentPackCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPlayerName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string seat_id = 1;
 * @return {string}
 */
proto.patrickwhite256.drafto.SeatData.prototype.getSeatId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.SeatData} returns this
 */
proto.patrickwhite256.drafto.SeatData.prototype.setSeatId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 pack_count = 2;
 * @return {number}
 */
proto.patrickwhite256.drafto.SeatData.prototype.getPackCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.patrickwhite256.drafto.SeatData} returns this
 */
proto.patrickwhite256.drafto.SeatData.prototype.setPackCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 pool_count = 3;
 * @return {number}
 */
proto.patrickwhite256.drafto.SeatData.prototype.getPoolCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.patrickwhite256.drafto.SeatData} returns this
 */
proto.patrickwhite256.drafto.SeatData.prototype.setPoolCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Card pool_revealed_cards = 4;
 * @return {!Array<!proto.patrickwhite256.drafto.Card>}
 */
proto.patrickwhite256.drafto.SeatData.prototype.getPoolRevealedCardsList = function() {
  return /** @type{!Array<!proto.patrickwhite256.drafto.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.patrickwhite256.drafto.Card, 4));
};


/**
 * @param {!Array<!proto.patrickwhite256.drafto.Card>} value
 * @return {!proto.patrickwhite256.drafto.SeatData} returns this
*/
proto.patrickwhite256.drafto.SeatData.prototype.setPoolRevealedCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.patrickwhite256.drafto.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.patrickwhite256.drafto.Card}
 */
proto.patrickwhite256.drafto.SeatData.prototype.addPoolRevealedCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.patrickwhite256.drafto.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.patrickwhite256.drafto.SeatData} returns this
 */
proto.patrickwhite256.drafto.SeatData.prototype.clearPoolRevealedCardsList = function() {
  return this.setPoolRevealedCardsList([]);
};


/**
 * repeated Card pack_revealed_cards = 5;
 * @return {!Array<!proto.patrickwhite256.drafto.Card>}
 */
proto.patrickwhite256.drafto.SeatData.prototype.getPackRevealedCardsList = function() {
  return /** @type{!Array<!proto.patrickwhite256.drafto.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.patrickwhite256.drafto.Card, 5));
};


/**
 * @param {!Array<!proto.patrickwhite256.drafto.Card>} value
 * @return {!proto.patrickwhite256.drafto.SeatData} returns this
*/
proto.patrickwhite256.drafto.SeatData.prototype.setPackRevealedCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.patrickwhite256.drafto.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.patrickwhite256.drafto.Card}
 */
proto.patrickwhite256.drafto.SeatData.prototype.addPackRevealedCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.patrickwhite256.drafto.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.patrickwhite256.drafto.SeatData} returns this
 */
proto.patrickwhite256.drafto.SeatData.prototype.clearPackRevealedCardsList = function() {
  return this.setPackRevealedCardsList([]);
};


/**
 * optional int32 current_pack_count = 6;
 * @return {number}
 */
proto.patrickwhite256.drafto.SeatData.prototype.getCurrentPackCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.patrickwhite256.drafto.SeatData} returns this
 */
proto.patrickwhite256.drafto.SeatData.prototype.setCurrentPackCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string player_name = 8;
 * @return {string}
 */
proto.patrickwhite256.drafto.SeatData.prototype.getPlayerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.SeatData} returns this
 */
proto.patrickwhite256.drafto.SeatData.prototype.setPlayerName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.GetCurrentUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.GetCurrentUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.GetCurrentUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.GetCurrentUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.GetCurrentUserReq}
 */
proto.patrickwhite256.drafto.GetCurrentUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.GetCurrentUserReq;
  return proto.patrickwhite256.drafto.GetCurrentUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.GetCurrentUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.GetCurrentUserReq}
 */
proto.patrickwhite256.drafto.GetCurrentUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.GetCurrentUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.GetCurrentUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.GetCurrentUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.GetCurrentUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.GetCurrentUserResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.GetCurrentUserResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    seatIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.GetCurrentUserResp}
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.GetCurrentUserResp;
  return proto.patrickwhite256.drafto.GetCurrentUserResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.GetCurrentUserResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.GetCurrentUserResp}
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSeatIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.GetCurrentUserResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.GetCurrentUserResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSeatIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.GetCurrentUserResp} returns this
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.GetCurrentUserResp} returns this
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string avatar_url = 3;
 * @return {string}
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.GetCurrentUserResp} returns this
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string seat_ids = 4;
 * @return {!Array<string>}
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.prototype.getSeatIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.patrickwhite256.drafto.GetCurrentUserResp} returns this
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.prototype.setSeatIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.patrickwhite256.drafto.GetCurrentUserResp} returns this
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.prototype.addSeatIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.patrickwhite256.drafto.GetCurrentUserResp} returns this
 */
proto.patrickwhite256.drafto.GetCurrentUserResp.prototype.clearSeatIdsList = function() {
  return this.setSeatIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.TakeSeatReq.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.TakeSeatReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.TakeSeatReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.TakeSeatReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    tableId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.TakeSeatReq}
 */
proto.patrickwhite256.drafto.TakeSeatReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.TakeSeatReq;
  return proto.patrickwhite256.drafto.TakeSeatReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.TakeSeatReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.TakeSeatReq}
 */
proto.patrickwhite256.drafto.TakeSeatReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.TakeSeatReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.TakeSeatReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.TakeSeatReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.TakeSeatReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string table_id = 1;
 * @return {string}
 */
proto.patrickwhite256.drafto.TakeSeatReq.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.TakeSeatReq} returns this
 */
proto.patrickwhite256.drafto.TakeSeatReq.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patrickwhite256.drafto.TakeSeatResp.prototype.toObject = function(opt_includeInstance) {
  return proto.patrickwhite256.drafto.TakeSeatResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patrickwhite256.drafto.TakeSeatResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.TakeSeatResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    tableId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seatId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patrickwhite256.drafto.TakeSeatResp}
 */
proto.patrickwhite256.drafto.TakeSeatResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patrickwhite256.drafto.TakeSeatResp;
  return proto.patrickwhite256.drafto.TakeSeatResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patrickwhite256.drafto.TakeSeatResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patrickwhite256.drafto.TakeSeatResp}
 */
proto.patrickwhite256.drafto.TakeSeatResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patrickwhite256.drafto.TakeSeatResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patrickwhite256.drafto.TakeSeatResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patrickwhite256.drafto.TakeSeatResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patrickwhite256.drafto.TakeSeatResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeatId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string table_id = 1;
 * @return {string}
 */
proto.patrickwhite256.drafto.TakeSeatResp.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.TakeSeatResp} returns this
 */
proto.patrickwhite256.drafto.TakeSeatResp.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string seat_id = 2;
 * @return {string}
 */
proto.patrickwhite256.drafto.TakeSeatResp.prototype.getSeatId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patrickwhite256.drafto.TakeSeatResp} returns this
 */
proto.patrickwhite256.drafto.TakeSeatResp.prototype.setSeatId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.patrickwhite256.drafto.Colour = {
  WHITE: 0,
  BLUE: 1,
  BLACK: 2,
  RED: 3,
  GREEN: 4
};

/**
 * @enum {number}
 */
proto.patrickwhite256.drafto.Rarity = {
  BASIC: 0,
  COMMON: 1,
  UNCOMMON: 2,
  RARE: 3,
  MYTHIC: 4
};

/**
 * @enum {number}
 */
proto.patrickwhite256.drafto.DraftMode = {
  PACK: 0,
  CUBE: 1
};

goog.object.extend(exports, proto.patrickwhite256.drafto);
